import React from 'react'
import CtaForm from '../../../components/CTA/CtaForm'

const CreateCtaPage = () => {
  return (
    <div>
      <CtaForm />
    </div>
  )
}

export default CreateCtaPage