import React from 'react'
import OverviewCta from '../../../components/CTA/OverviewCta'

const OverviewPageCTA = () => {
  return (
    <div>
      <OverviewCta />
    </div>
  )
}

export default OverviewPageCTA