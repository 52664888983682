import React from 'react'
import OverviewArticlesComponent from '../../../components/Articles/OverviewArticles'

const OverviewArticles = () => {
  return (
    <div>
      <OverviewArticlesComponent />
    </div>
  )
}

export default OverviewArticles;