import React from 'react'
import UserForm from '../../../components/Users/UserForm'

const CreateUserPage = () => {
  return (
    <div>
      <UserForm />
    </div>
  )
}

export default CreateUserPage